
export function getRole() {
    return localStorage.getItem("role") as "academy" | "school" | null;
}


export const languageList = [
    { code: 'zh', name: 'Chinese' },
    { code: 'en', name: 'English' },
    { code: 'ms', name: 'Malay' },
]