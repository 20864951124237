import React, { useState } from 'react'
import BlankLayout from '../../component/layout/BlankLayout'
import IconMail from '../../component/icon/IconMail'
import IconLockDots from '../../component/icon/IconLockDots'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../api/api'
import axios from 'axios'
import IconSpinner from '../../component/icon/IconSpinner'
import { toast } from 'react-toastify'
import Dropdown from '../../component/Dropdown'
import i18next from 'i18next'
import IconGlobe from '../../component/icon/IconGlobe'
import { useTranslation } from 'react-i18next'
import { languageList } from '../../util/helper'

type Props = {}

const Login = (props: Props) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const submitForm = async () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/login`, {
            email: email,
            password: password,
            role:"coach"
        }).then( res => {
            setLoading(false)
            // console.log("res", res)
            localStorage.setItem("user", JSON.stringify(res.data.user))
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("role", res.data.user.school_id ? "school" : "academy")
            localStorage.setItem("firstName", res.data.user.first_name)
            navigate("/")
        }).catch( err => {
            setLoading(false)
            console.log("err", err)
            if (err && err.response && err.response.data && err.response.data.error) {
                return toast.error(err.response.data.error)
            }
            return toast.error("Invalid credentials")
        })
    }

    return (
        <BlankLayout>
        <div>
            <div className="relative flex min-h-screen items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16">
                <div className="relative w-full max-w-[870px] rounded-md bg-[linear-gradient(45deg,#fff9f9_0%,rgba(255,255,255,0)_25%,rgba(255,255,255,0)_75%,_#fff9f9_100%)] p-2 dark:bg-[linear-gradient(52.22deg,#0E1726_0%,rgba(14,23,38,0)_18.66%,rgba(14,23,38,0)_51.04%,rgba(14,23,38,0)_80.07%,#0E1726_100%)]">
                    <div className="relative flex flex-col justify-center rounded-md backdrop-blur-lg dark:bg-black/50 px-6 lg:min-h-[758px] py-20">
                        <div className="mx-auto w-full max-w-[440px]">
                            <div className="mb-10">
                                <img className="w-20" src="/assets/images/koku.png" alt="logo"/>
                                <h1 className="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl">{t('login.coach_login')}</h1>
                                <p className="text-base font-bold leading-normal text-white-dark">{t('login.coach_login')}</p>
                            </div>
                            <div className="space-y-5 dark:text-white">
                                <div>
                                    <label>{t('login.email')}</label>
                                    <div className="relative text-white-dark">
                                        <input value={email} onChange={(e)=>{setEmail(e.target.value)}} type="email" placeholder={t('login.enter_email')} className="form-input ps-10 placeholder:text-white-dark text-[16px]" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label>{t('login.password')}</label>
                                    <div className="relative text-white-dark">
                                        <input value={password} onChange={(e)=>{setPassword(e.target.value)}} type="password" placeholder={t('login.enter_password')} className="form-input ps-10 placeholder:text-white-dark text-[16px]" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <button type="button" onClick={(e)=>(submitForm())} className="btn btn-primary rounded-3xl !mt-6 w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]">
                                    {loading ? <IconSpinner className="animate-spin"/> : t('login.login_button')}
                                </button>
                                <div className="text-center dark:text-white">
                                {t('login.dont_have_account')}&nbsp;
                                    <Link to="/register" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                        {t('login.register')}
                                    </Link>
                                </div>
                                <div className="text-center dark:text-white">
                                {t('login.forgot_password')}&nbsp;
                                    <Link to="/reset-password" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                        {t('login.reset')}
                                    </Link>
                                </div>

                                <div>
                                    <Dropdown placement={`bottom-start`} btnClassName=" p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60" button={<IconGlobe/>} >
                                        <ul className="p-2 text-dark dark:text-white-dark grid grid-cols-2 gap-2 font-semibold dark:text-white-light/90 w-[280px]">
                                            {languageList.map((item: any) => {
                                                return (
                                                    <li key={item.code}>
                                                        <button
                                                            type="button"
                                                            className={`flex pt-2 w-full hover:text-primary rounded-lg ${i18next.language === item.code ? 'bg-primary/10 text-primary' : ''}`}
                                                            onClick={() => {
                                                                i18next.changeLanguage(item.code)
                                                                // setFlag(item.code);
                                                            }}
                                                        >
                                                            <img src={`/assets/images/flags/${item.code.toUpperCase()}.svg`} alt="flag" className="w-5 h-5 object-cover rounded-full" />
                                                            <span className="ltr:ml-3 rtl:mr-3">{item.name}</span>
                                                        </button>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </BlankLayout>
    )
}

export default Login