import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import IconMail from '../../component/icon/IconMail'
import axios from 'axios'
import BlankLayout from '../../component/layout/BlankLayout'
import { toast } from 'react-toastify'
import Dropdown from '../../component/Dropdown'
import { languageList } from '../../util/helper'
import i18next from 'i18next'
import IconGlobe from '../../component/icon/IconGlobe'
import { useTranslation } from 'react-i18next'

type Props = {}

const Register = (props: Props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [otp, setOtp] = useState("")
    const [showOtp, setShowOtp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [timeLeft, setTimeLeft] = useState(0)

    // Function to start the timer and store timestamp
    const startTimer = () => {
        const now = Date.now()
        const expireTime = now + 60 * 1000 // 1-minute expiry
        localStorage.setItem("otp_timer", expireTime.toString())
        setTimeLeft(60)
    }

    // Effect to check the timer on mount
    useEffect(() => {
        const storedExpireTime = localStorage.getItem("otp_timer")
        if (storedExpireTime) {
            const remainingTime = Math.floor((parseInt(storedExpireTime) - Date.now()) / 1000)
            if (remainingTime > 0) {
                setTimeLeft(remainingTime)
            }
        }

        // Timer countdown
        const interval = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(interval)
                    localStorage.removeItem("otp_timer")
                    return 0
                }
                return prev - 1
            });
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    const generateOtp = async () => {
        if (email.trim() === "") return toast.error(t('register.email_required'))

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/generate-otp`, {
            email: email.trim(),
        }).then( res => {
            setLoading(false)
            console.log("res", res)
            // If user not exist, would be new user. So we will be also need to ask user to fill in all the info that we needed
            // show otp column
            setShowOtp(true)
            startTimer() // Start timer after OTP request
        }).catch( err => {
            setLoading(false)
            console.log("err", err)
            if (err.response.data && err.response.data.message) {
                toast.error(err.response.data.message)
            } else {
                toast.error(t("server_error"))
            }
        })
    }

    const checkExist = async () => {
        if (email.trim() === "" || otp.trim() === "") return toast.error(t('register.email_otp_required'))

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/exist`, {
            "email": email.trim(),
            "otp": otp.trim()
        }, {headers: {'Content-Type': 'application/json', "Accept":"application/json"}, withCredentials: true }).then( res => {
            setLoading(false)
            console.log("res check exist", res)
            if (res.data.is_exist === false) {
                navigate(`/register-new?email=${email}`)
            } else {
                navigate(`/register-exist?email=${email}`)
            }
        }).catch( err => {
            setLoading(false)
            console.log("err", err.response)
            toast.error("Error validating user account")
        })
    }



    return (
        <BlankLayout>
            <div className="relative flex min-h-screen items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16">
                <div className="relative w-full max-w-[870px] rounded-md bg-[linear-gradient(45deg,#fff9f9_0%,rgba(255,255,255,0)_25%,rgba(255,255,255,0)_75%,_#fff9f9_100%)] p-2 dark:bg-[linear-gradient(52.22deg,#0E1726_0%,rgba(14,23,38,0)_18.66%,rgba(14,23,38,0)_51.04%,rgba(14,23,38,0)_80.07%,#0E1726_100%)]">
                    <div className="relative flex flex-col justify-center rounded-md backdrop-blur-lg dark:bg-black/50 px-6 lg:min-h-[758px] py-20">
                        <div className="mx-auto w-full max-w-[440px]">
                            <div className="mb-10">
                                <img className="w-20" src="/assets/images/koku.png" alt="logo"/>
                                <h1 className="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl">{t('register.sign_up')}</h1>
                                <p className="text-base font-bold leading-normal text-white-dark">{t('register.enter_email_password')}</p>
                            </div>
                            <div className="space-y-5 dark:text-white">
                                <div>
                                    <label>{t('register.email')}</label>
                                    <div className="relative text-white-dark">
                                        <input type="email" placeholder={t('register.enter_email')} value={email} onChange={(e)=>setEmail(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                </div>
                                {showOtp && (
                                <div>
                                    <label>{t('register.otp')}</label>
                                    <div className="relative text-white-dark">
                                        <input type="text" placeholder={t('register.enter_otp')} value={otp} onChange={(e)=>setOtp(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                </div>
                                )}
                                <button type="button" className="btn btn-primary rounded-3xl !mt-6 w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]"  onClick={showOtp ? checkExist : generateOtp} disabled={(!showOtp && loading) || (!showOtp && timeLeft > 0)}>
                                    {loading ? (
                                        <span className="animate-spin border-2 border-white dark:border-white !border-l-transparent rounded-full w-5 h-5 inline-flex"></span>
                                    ) : (
                                        showOtp ? t('register.verify_otp') : timeLeft > 0 ? `Resend OTP (${timeLeft}s)` : t("register.send_otp")
                                    )}
                                </button>
                            </div>
                            <div className="relative my-7 text-center md:mb-9">
                                <span className="absolute inset-x-0 top-1/2 h-px w-full -translate-y-1/2 bg-white-light dark:bg-white-dark"></span>
                                <span className="relative bg-white px-2 font-bold uppercase text-white-dark dark:bg-dark dark:text-white-light">{t('register.or')}</span>
                            </div>
                            <div className="text-center dark:text-white">
                                {t('register.already_have_account')}&nbsp;
                                <Link to="/login" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                    {t('register.sign_in')}
                                </Link>
                            </div>

                            <div>
                                <Dropdown placement={`bottom-start`} btnClassName=" p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60" button={<IconGlobe/>} >
                                    <ul className="p-2 text-dark dark:text-white-dark grid grid-cols-2 gap-2 font-semibold dark:text-white-light/90 w-[280px]">
                                        {languageList.map((item: any) => {
                                            return (
                                                <li key={item.code}>
                                                    <button
                                                        type="button"
                                                        className={`flex pt-2 w-full hover:text-primary rounded-lg ${i18next.language === item.code ? 'bg-primary/10 text-primary' : ''}`}
                                                        onClick={() => {
                                                            i18next.changeLanguage(item.code)
                                                            // setFlag(item.code);
                                                        }}
                                                    >
                                                        <img src={`/assets/images/flags/${item.code.toUpperCase()}.svg`} alt="flag" className="w-5 h-5 object-cover rounded-full" />
                                                        <span className="ltr:ml-3 rtl:mr-3">{item.name}</span>
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BlankLayout>
    )
}

export default Register