import { useState, Fragment, useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { IRootState } from '../../../store';
import { useSelector } from 'react-redux';
import api from '../../../api/api';
import { toast } from 'react-toastify';
import Loader from '../../../component/layout/Loader';
import { t } from 'i18next';

type Props = {}

const PAGE_SIZES = [10, 20, 30, 50, 100]
const AttendanceView = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const params = useParams()

    const [courseData, setCourseData] = useState<any>({})
    const capturedImage = useSelector((state: IRootState) => state.attendance.capturedImage)
    
    const [description, setDescription] = useState("")
    const [selectedCoaches, setSelectedCoaches] = useState<any>([])
    const [selectedStudents, setSelectedStudents] = useState<any>([])


    const handleCheckboxChange = (selectedId:number, type: string) => {
        if (type=="coach") {
            setSelectedCoaches((prev:any) => prev.includes(selectedId) ? prev.filter((coachId:any) => coachId !== selectedId) : [...prev, selectedId] )
        } else if (type=="student") {
            setSelectedStudents((prev:any) => prev.includes(selectedId) ? prev.filter((coachId:any) => coachId !== selectedId) : [...prev, selectedId] )
        }
    }

    useEffect(() => {
        setLoading(true)
        api.get(`${process.env.REACT_APP_API_BASE_URL}/course/schedule/${params.id}/${params.date}`).then(res => {
            setLoading(false)
            console.log("res", res)
            setCourseData(res.data.data)

            // default make all student attendace taken
            setSelectedStudents(res.data.data.students.map((student:any) => student.id))
        }).catch(err => {
            setLoading(false)
            console.log("err", err)
        })
    }, [])


    function handleSubmitAttendance() {
        console.log("handleSubmitAttendance")
        setLoading(true)
        api.post(`${process.env.REACT_APP_API_BASE_URL}/attendance/add`, {
            "course_schedule_id": params.id,
            "course_date": params.date,
            "class_pic": capturedImage,
            "description": description,
            "students": selectedStudents,
            "coaches": selectedCoaches
        }).then(res => {
            setLoading(false)
            console.log("res", res)
            if (res.status == 200) {
                toast.success(t("attendance.attendance_taken"))
                return navigate('/schedule')
            }
            toast.error(t("server_error"))
        }).catch(err => {
            setLoading(false)
            console.log("err", err)
            if (err.response && err.response.data && err.response.data.errors) {
                const errors = err.response.data.errors
                Object.keys(errors).forEach(key => {
                    errors[key].forEach((message:string) => {
                        toast.error(`${key} : ${message}`)
                    })
                })
            } else {
                toast.error(t("server_error"))
            }
        })
    }
    

    return (
        <div>
            {loading && <Loader/>}
            <div className="flex items-center justify-between flex-wrap gap-4">
                <h2 className="text-xl">{(courseData.course && courseData.course.name) || "-"} ({courseData.students && courseData.students.length})</h2>
            </div>

            <div className='grid grid-cols-2 my-3 mb-5 gap-2'>
                <div>
                    <div className='mb-2'>{t('date')}: {courseData.date}</div>
                    <div>{t('course_name')}: {courseData.course && courseData.course.name}</div>
                </div>
                <div>
                    <div className='mb-2'>{t('time')}: {courseData.time}</div>
                    <div>{t('branch_name')}: {courseData.branch_name}</div>
                </div>
            </div>
            
            {capturedImage ? (
                <div className="my-5 text-center">
                    <img src={capturedImage} alt="Captured" className="rounded-md  max-h-72 mx-auto" />
                </div>
            ) : (
                <p>{t('attendance.no_image')}</p>
            )}
            <div className="my-5">
                <label htmlFor="address">{t("attendance.description")}</label>
                <textarea rows={3} value={description} onChange={(e)=>{setDescription(e.target.value)}} placeholder={t("attendance.enter_description")} className="form-textarea resize-none min-h-[130px]" ></textarea>
            </div>


            <div className='text-lg'>{t("attendance.coaches")}</div>
            <div className="mt-5 panel p-0 border-0 overflow-hidden">
                <div className="table-responsive">
                    <table className="table-striped table-hover">
                        <thead>
                            <tr>
                                <th>{t('name')}</th>
                                <th>{t('attendance.attended')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {courseData.coaches && courseData.coaches.map((item:any, index:number) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div>{item.name}</div>
                                    </td>
                                    <td>
                                        <div>
                                            <label className="inline-flex cursor-pointer">
                                                <input type="checkbox" checked={selectedCoaches.includes(item.id)} onChange={()=>{handleCheckboxChange(item.id, "coach")}} className="form-checkbox" />
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>


            <div className='text-lg mt-5'>{t('attendance.students')}</div>
            <div className="mt-5 panel p-0 border-0 overflow-hidden">
                <div className="table-responsive">
                    <table className="table-striped table-hover">
                        <thead>
                            <tr>
                                <th>{t('name')}</th>
                                <th>{t('attendance.attended')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {courseData.students && courseData.students.map((item:any, index:number) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div>{item.name}</div>
                                    </td>
                                    <td>
                                        
                                        <div>
                                            <label className="inline-flex cursor-pointer">
                                                <input type="checkbox" checked={selectedStudents.includes(item.id)} onChange={()=>{handleCheckboxChange(item.id, "student")}} className="form-checkbox" />
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>

            
            <div className="grid grid-cols-4 mt-10">
                <div className="col-span-2 mb-5 justify-self-start" onClick={()=>{navigate("/schedule")}}>
                    <button type="button" className="btn btn-secondary">{t('back')}</button>
                </div>
                <div className="col-span-2 mb-5 justify-self-end" onClick={()=>{ handleSubmitAttendance() }}>
                    <button type="button" className="btn btn-primary">{t('submit')}</button>
                </div>
            </div>

        </div>

    )
}

export default AttendanceView