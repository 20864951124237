import React, { useCallback, useEffect, useState } from 'react'
import IconUser from '../../component/icon/IconUser'
import { useParams } from 'react-router-dom'
import api from '../../api/api'
import Loader from '../../component/layout/Loader'
import parse from 'html-react-parser'
import { Document, Page, pdfjs } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const InboxView = () => {
    const [notification, setNotification] = useState<any>({})
    const [loading, setLoading] = useState(false)
    let { id } = useParams()

    useEffect(() => {
        setLoading(true)
        api.get(`${process.env.REACT_APP_API_BASE_URL}/notification/${id}`).then(res => {
            console.log("notification", res.data.data)
            setNotification(res.data.data)
        }).catch(err => {
            console.log("err notification", err)
        }).finally(() => {
            setLoading(false)
            readNotification(id)
        })
    }, [])

    function readNotification(notificationId:any) {
        api.post(`${process.env.REACT_APP_API_BASE_URL}/notification/seen/${notificationId}`).then(res => {
            console.log("read", res.data)
        }).catch(err => {
            console.log("err cart", err)
        })
    }


    function formatDate(dateString: string) {
        const date = new Date(dateString)
        
        const day = date.getUTCDate()
        const month = date.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' })
        const year = date.getUTCFullYear()
        const hours = date.getUTCHours()
        const minutes = date.getUTCMinutes().toString().padStart(2, '0')
        const period = hours >= 12 ? 'PM' : 'AM'
        const formattedHours = hours % 12 || 12 // Convert to 12-hour format

        const formattedDate = `${month} ${day}, ${year}, ${formattedHours}:${minutes} ${period}`

        return formattedDate
    }

    // react-pdf
    const [numPages, setNumPages] = useState<number>()
    const [currentPage, setCurrentPage] = useState(1)
    

    const getFileExtension = (url: string) => {
        const cleanUrl = url.split("?")[0] // Remove query parameters
        return cleanUrl.split(".").pop()?.toLowerCase() // Get the last part after '.'
    }
    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages || 1))
    };

    const AttachmentViewer = ({ fileUrl }: { fileUrl: string }) => {
        const extension:any = getFileExtension(fileUrl)

        if (extension === "pdf") {
            return (
                <div className='text-center'>
                    <Document
                        file={fileUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{
                            cMapUrl: '/cmaps/',
                            standardFontDataUrl: '/standard_fonts/',
                        }}
                    >
                        <Page pageNumber={currentPage} />
                    </Document>
        
                    <div style={{ marginTop: 10 }}>
                        <button onClick={goToPreviousPage} disabled={currentPage <= 1}>
                            Previous
                        </button>
                        <span style={{ margin: "0 10px" }}>
                            Page {currentPage} of {numPages}
                        </span>
                        <button onClick={goToNextPage} disabled={currentPage >= (numPages || 1)}>
                            Next
                        </button>
                    </div>
                </div>
            )
        } else if (["png", "jpg", "jpeg"].includes(extension)) {
            return <img src={fileUrl} alt="attachment" height={250} width={250}/>
        } else {
            return <p>Unsupported file type</p>
        }
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumPages(nextNumPages)
    }

    return (
        <>
        {loading && <Loader/>}
        <div className="panel">
            <div className="mb-5 grid grid-cols-5">
                <div className='col-span-1'>
                    <div className="w-max">
                        <img src={notification.sender_pic} className="h-8 w-8 rounded-full object-cover ltr:mr-2 rtl:ml-2" alt="avatar" />
                    </div>
                </div>
                <h5 className="col-span-4 dark:text-white-light grid grid-rows-2">
                    <div className='text-xl font-medium '>{notification.sender_name || "-"}</div>
                    <div>{notification.sent_at ? formatDate(notification.sent_at) : "-"}</div>
                </h5>
            </div>
            <div className="mb-5 font-medium text-xl">
                {notification.title || "-"}
            </div>
            <div className="">
                {notification.descriptions ? parse(notification.descriptions) : "-"}
            </div>

            {notification.attachements && notification.attachements.length > 0 && (
            <div className="mt-5 justify-items-center">
                {notification.attachements.map((attachment:string) => {
                    return (
                        <AttachmentViewer fileUrl={attachment} />
                    )
                })}
            </div>
            )}
        </div>
        </>
    )
}

export default InboxView